import { CssBaseline, ThemeProvider } from '@mui/material'
import React, { Component, useContext } from 'react'
import Sidebare from '../global/Sidebare'
import FooterAdmin from '../global/FooterAdmin'
import Topbar from '../global/Topbar'
import { ColorModeContext, useMode,ColorModeProvider, themeSettings } from "../../theme"
import { ProSidebarProvider } from 'react-pro-sidebar';
import { NotificationProvider } from "../context/notificationContext"
import { SettingsProvider, SettingsContext } from "../context/settingsContext"
import Loading from './Loading'

function withMyHook(Component) {

    return function WrappedComponent(props) {
        //const { mode } = useMode()
        const theme = themeSettings('dark')
        const { isCollapsed, setIsCollapsed } = useContext(SettingsContext)
        console.log(theme)

        return (
            <Component isCollapsed={isCollapsed} theme={theme}  {...props} />
        )
    }
    
}

class Apps extends Component {
    
    static contextType = SettingsContext
    constructor(props) {
        super(props)
        this.state = {
            colorMode: props.colorMode,
        }
    }

    render() {
        return (
            <ProSidebarProvider>
                <NotificationProvider>
                    <ColorModeProvider >
                            <CssBaseline />
                            <div className="app " >
                                <Sidebare user={this.props.user} />
                                {
                                    !this.context.isCollapsed &&
                                    <div className=' fixed top-0 left-0 w-full h-full bg-[#00000080] z-[55]'>
                                    </div>
                                }
                                <main className={`content h-max ml-[50px] `} >
                                    <Topbar />
                                    <div style={{background:this.props.theme.palette.background.main,padding:'20px'}}
                                    className=' min-h-[1000px]'
                                    >
                                        {this.props.Com}
                                    </div>
                                    <FooterAdmin />
                                </main>
                            </div>
                    </ColorModeProvider>
                </NotificationProvider>
            </ProSidebarProvider >
        )
    }
}
export default withMyHook(Apps);