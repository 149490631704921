import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.css?v=2";
import { Carousel } from 'react-responsive-carousel';
import { SettingsContext } from '../../context/settingsContext';
import { sliders_path } from '../../../paths';
//import { Carousel } from "flowbite-react";
import { CustomFlowbiteTheme } from "flowbite-react";
import { Flowbite } from "flowbite-react";

const customTheme: CustomFlowbiteTheme = {
  carousel: {
    "root": {
      "base": "relative h-full w-full",
      "leftControl": "absolute left-0 top-0 flex h-full items-center justify-center px-4 focus:outline-none",
      "rightControl": "absolute right-0 top-0 flex h-full items-center justify-center px-4 focus:outline-none"
    },
    "indicators": {
      "active": {
        "off": "bg-white/50 hover:bg-gray-800",
        "on": "bg-white h-4 w-4"
      },
      "base": "h-3 w-3 rounded-full",
      "wrapper": "absolute left-1/2 flex -translate-x-1/2 space-x-3 w-full p-6 bottom-0 justify-center bg-black/60  items-center"
    },
    "item": {
      "base": "absolute left-1/2 top-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
      
    },
    "control": {
      "base": "inline-flex h-10 w-10 items-center justify-center rounded-full bg-black/30 group-hover:bg-black/50 group-focus:outline-none  group-focus:ring-white dark:bg-gray-800/30 dark:group-hover:bg-gray-800/60 dark:group-focus:ring-gray-800/70 sm:h-16 sm:w-16",
      "icon": "h-5 w-5 text-white dark:text-gray-800 sm:h-6 sm:w-6"
    },
    "scrollContainer": {
      "base": "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth",
      "snap": "snap-x"
    }
  },
};
/*const Sliders = (props) => {
    return (
        <Flowbite theme={{ theme: customTheme }}>
        <div className="sm:h-[24rem] md:h-[32rem] lg:h-[27rem] h-[20rem] border border-black/40 ">
            <Carousel pauseOnHover className="rounded-none">
                {props.images.map((image, i) => {
                    return <img src={`${sliders_path}${image.content}`} alt="..." className="h-full" />
                })}
            </Carousel>
        </div>
        </Flowbite>
    );
}
*/
const Sliders = (props) => {
    const navigate = useNavigate()
  const { setPost } = useContext(SettingsContext)
  const handleSetPost =(post) =>{
    setPost(post)
    navigate(`/en/posts/${post.title}`)
  }

    return (
        <Carousel className='flex flex-col w-full'  {...props} >
            {
                props.images.map((image, i) => {
                    return (
                        <div key={Math.random(500)}>
                            <img src={`${sliders_path}${image.content}`} />
                            <p className="absolute left-[50%] w-[90%] p-3 text-center ml-[-45%] text-sm bottom-[20px] text-white bg-[#00000020]">{image.description}</p>
                            {
                                image.post &&
                                <a href={image.post.title} className="absolute top-[20px] p-2 text-center text-sm right-[20px] text-white bg-green-700">More...</a>
                            }
                        </div>
                    )
                }) 
            }


        </Carousel>
    );
}

export default Sliders

