import { Box } from "@mui/material";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import React, { useEffect, useState, useCallback, useContext } from "react";
import Table from "../../components/Table";
import _R from "../../components/api";
import Loading from "../layouts/Loading";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { FilterContext } from "../context/filterContext";
import { NotificationContext } from "../context/notificationContext";
import Notification from "../global/Notification";

const Users = () => {
  const { notification, setNotification } = useContext(NotificationContext);
  const { filter } = useContext(FilterContext);

  const [node, setNode] = useState(null);
  const [filterUsers, setFilterUsers] = useState(null);

  // Reference for the table element
  const measuredRef = useCallback(node => {
    if (node !== null) {
      setNode(node);
    }
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await _R.get("/getUsers");
        setFilterUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
        setNotification({ type: "error", message: "Failed to fetch users." });
      }
    };
    fetchUsers();
  }, [setNotification]);

  const columns = [
    { field: "id", use: "ID", type: "integer" },
    { field: "firstname", use: "First Name", type: "string" },
    { field: "lastname", use: "Last Name", type: "string" },
    { field: "email", use: "Email", type: "string", color: "text-yellow-300" },
    { field: "idc1", use: "Facade1", type: "file" },
    { field: "idc2", use: "Facade2", type: "file" },
    { field: "document_status", use: "Account?", type: "list" },
    { field: "role", use: "Role", type: "string" },
    { field: "created_at", use: "Last UPDATE", type: "string" },
    { field: "action", use: "Action", type: "button" },
  ];

  return filterUsers ? (
    <Box m="20px">
      <Header title="USERS" subtitle="Manage all users .." />
      <Box textAlign="right">
        <Link
          to="/en/admin/users/add"
          className="btn bg-green-600 hover:bg-green-700 p-1 text-green-100 text-md mx-2"
        >
          Add user
        </Link>
        <DownloadTableExcel filename="users table" sheet="users" currentTableRef={node}>
          <button className="btn bg-blue-600 hover:bg-blue-700 p-1 text-blue-100 text-md">
            Export Excel
          </button>
        </DownloadTableExcel>
      </Box>
      <div ref={measuredRef}>
        <Table
          model="users"
          rows={filterUsers}
          columns={columns}
          setRows={setFilterUsers}
          filter={filter}
          widths={[50, 150, 150, 250, 150, 150, 150, 150, 200, 100]}
          action={[
            { button: "view", link: "/en/admin/users/show/" },
            { button: "approve", link: "/en/admin/users/show/" },
          ]}
        />
      </div>
      {notification && <Notification info={notification} />}
    </Box>
  ) : (
    <Loading />
  );
};

export default Users;
